import React from 'react'
import { Link } from 'gatsby'

import useSiteMetadata from '../queries/site-metadata'

import Logo from './Logo'

export default function Navbar() {
  const { title } = useSiteMetadata()

  return (
    <header className="flex items-center justify-between py-2 bg-gray-800 text-main w-full">
      <Link to={`/`} className="px-2 lg:px-0 ml-6">
        <Logo title={title} />
      </Link>
      <ul className="inline-flex items-center">
        <li key="navbar-blog-index" className="px-2 lg:px-4">
          <Link to={`/why-contract-us`} className="text-gray-300 font-semibold hover:text-main p-4 bg-gray-600 rounded uppercase">why contract us? Your advantages</Link>
          <Link to={'/contact'} className="text-gray-300 font-semibold hover:text-main p-4 bg-gray-600 rounded ml-2 mr-6 uppercase">call us!</Link>

        </li>
      </ul>
    </header>
  )
}

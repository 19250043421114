import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Logo({ title, className }) {
  return (
    <div className="flex items-center justify-center">
        <FontAwesomeIcon icon="language" size='2x' className="ml-4"/>
        <h1 className="p-4 text-2xl text-gray-200">IPPAL Translation Company</h1>
    </div>
    // <StaticImage
    //   src="../images/gatsby-icon.png"
    //   formats={['auto', 'webp', 'avif']}
    //   alt={title}
    //   title={title}
    //   className={className}
    //   placeholder="blurred"
    //   width={50}
    //   height={50}
    // />
  )
}

Logo.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
